.react-pdf__Page__canvas {
    margin: 0 auto;
    width: 80% !important;
    height: 100% !important;
}
table.table-fit {
    width: auto !important;
    table-layout: auto !important;
}
table.table-fit thead th, table.table-fit tfoot th {
    width: auto !important;
}
table.table-fit tbody td, table.table-fit tfoot td {
    width: auto !important;
}
body {
    background-color: hsl(0, 0%, 95%) !important;
}
table {
    background-color: #fff;
}
.active {
    text-decoration: underline;
}
.pagination {
    margin: 0;
}
.pagination li {
    display: inline-block;
    padding: 0 5px;
    margin: 5px;
}
th:first-child {
    position: sticky;
    left: 0;
    background-color: #fff !important;
}
td:first-child  {
    background-color: #fff !important;
    position: sticky;
    left: 0;
    z-index: 1;
    border: #fff;
}
thead {
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 101;
}
.filterable-table-container .header-row {
    margin-top: 6px;
}
.filterable-table-container .header-row .exact-filters {
    margin-top: 20px;
}
.filterable-table-container .header-row .record-count {
    padding-top: 7px;
}
.filterable-table-container table.filterable-table thead tr th {
    white-space: nowrap;
    user-select: none;
}
.filterable-table-container table.filterable-table thead tr th span.fa {
    padding-left: 8px;
    color: #aaa;
}
.filterable-table-container table.filterable-table tfoot tr td {
    font-weight: 700;
}
.filterable-table-container .filter-container {
    position: relative;
}
.filterable-table-container .filter-container .filter-input, .filterable-table-container .filter-container .page-size {
    width: 185px;
    display: inline;
}
.filterable-table-container .clear-filter {
    position: absolute;
    right: 5px;
    top: 1px;
}
.filterable-table-container span.sortable, .filterable-table-container span.filterable {
    cursor: pointer;
}
.filterable-table-container span.filterable {
    border-bottom: 1px solid #91c1ff;
}
.filterable-table-container ul.pagination li a {
    user-select: none;
}
.filterable-table-container ul.pagination li:not(.disabled) a {
    cursor: pointer;
}
.filterable-table-container span.empty {
    font-style: italic;
    color: #999;
}
.filterable-table-container .filter-item {
    border: 1px solid #ccc;
    border-radius: 1px;
    display: inline-block;
    margin-right: 15px;
}
.filterable-table-container .filter-item .filter-item-title {
    background-color: #2f6e9b;
    border-radius: 1px;
    color: #fff;
}
.filterable-table-container .filter-item .filter-item-title .filter-item-remove {
    color: #fff;
    font-weight: 700;
    user-select: none;
    padding-right: 10px;
    cursor: pointer;
}
.filterable-table-container .filter-item .filter-item-title, .filterable-table-container .filter-item .filter-item-value {
    padding: 5px 10px;
    display: inline-block;
}
